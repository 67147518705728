import React, { useRef, useEffect } from "react";
import mapboxgl from '!mapbox-gl';
import { Link } from "gatsby"
import SEO from '../components/seo'
/* Map spesific */
import useSWR from "swr";
import "mapbox-gl/dist/mapbox-gl.css";
import './map.css';

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

/* This will let you use the .remove() function later on
      if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function () {
          if (this.parentNode) {
            this.parentNode.removeChild(this);
          }
        };
      }
*/

      mapboxgl.accessToken = MAPBOX_TOKEN;


      function MapPage() {
        const mapboxElRef = useRef(null); // DOM element to render map
      
        const fetcher = url =>
          fetch(url)
            .then(r => r.json())
            .then(data =>
              data.result.map((point, index) => ({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [
                    point.coordinates.lng,
                    point.coordinates.lat
                  ]
                },
                properties: {
                  id: index,
                  title: point.title,
                  date: point.date,
                  slug: point.slug.current,
                  casesNotInUse: point.address
                }
              }))
            );
      
        const { data } = useSWR("https://br77nm76.apicdn.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20%22location%22%5D%20%7C%20order(dato%20asc)%20%7B%0A%20%20_id%2C%0A%20%20title%2C%0A%20%20date%2C%0A%20%20address%2C%0A%20%20excerpt%2C%0A%20%20slug%2C%0A%20%20coordinates%20%7B%0A%20%20%20%20lat%2C%0A%20%20%20%20lng%0A%20%20%7D%2C%0A%09mainImage%2C%0A%09description%0A%7D", fetcher);
        /* https://my-json-server.typicode.com/stamfar/jsonplaceholder/db
      https://br77nm76.apicdn.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20%22location%22%5D%20%7C%20order(dato%20asc)%20%7B%0A%20%20_id%2C%0A%20%20title%2C%0A%20%20date%2C%0A%20%20coordinates%20%7B%0A%20%20%20%20lat%2C%0A%20%20%20%20lng%0A%20%20%7D%2C%0A%20%20stats%20%7B%0A%20%20%20%20confirmed%2C%0A%20%20%20%20recovered%0A%20%20%7D%2C%0A%09mainImage%2C%0A%09description%0A%7D
         */
      
        // Initialize our map
        useEffect(() => {
          if (data) {
            const map = new mapboxgl.Map({
              container: mapboxElRef.current,
              style: "mapbox://styles/mainly/ckluwjeue3ty217pt1n6v3q1y",
              center: [7.9612783, 58.1529583],
              zoom: 11.5,
            });
            // Add navigation controls to the top right of the canvas
            map.addControl(new mapboxgl.NavigationControl());
      
            /* START Hentet fra annet prosjekt */
            data.forEach(function (store, i) {
              store.properties.id = i;
            });
            /* Slutt Hentet fra annet prosjekt */
      
            
      /* map.once kan også være map.on hvis problemer...*/
            map.once("load", function(e) {
              // Add our SOURCE
              map.addSource("points", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: data
                }
              });
      
      
              buildLocationList(data);
              addMarkers();
      
      
              const popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: true
              });
      
          
      
            });
      
      
            /**
             * Add a marker to the map for every store listing.
             **/
             function addMarkers() {
              /* For each feature in the GeoJSON object above: */
              data.forEach(function (marker) {
                /* Create a div element for the marker. */
                var el = document.createElement('div');
                /* Assign a unique `id` to the marker. */
                el.id = 'marker-' + marker.properties.id;
                /* Assign the `marker` class to each marker for styling. */
                el.className = 'marker';
      
                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.
                 **/
                new mapboxgl.Marker(el, { offset: [0, -23] })
                  .setLngLat(marker.geometry.coordinates)
                  .addTo(map);
      
                /**
                 * Listen to the element and when it is clicked, do three things:
                 * 1. Fly to the point
                 * 2. Close all other popups and display popup for clicked store
                 * 3. Highlight listing in sidebar (and remove highlight for all other listings)
                 **/
                el.addEventListener('click', function (e) {
                  /* Fly to the point */
                  flyToStore(marker);
                  /* Close all other popups and display popup for clicked store */
                  createPopUp(marker);
                  /* Highlight listing in sidebar */
                  var activeItem = document.getElementsByClassName('active');
                  e.stopPropagation();
                  if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                  }
                  var listing = document.getElementById(
                    'listing-' + marker.properties.id
                  );
                  listing.classList.add('active');
                });
              });
            }
      
              /**
             * Add a listing for each store to the sidebar.
             **/
               function buildLocationList(data) {
                data.forEach(function (store, i) {
                  /**
                   * Create a shortcut for `store.properties`,
                   * which will be used several times below.
                   **/
                  var prop = store.properties;
        
                  /* Add a new listing section to the sidebar. */
                  var listings = document.getElementById('listings');
                  var listing = listings.appendChild(document.createElement('div'));
                  /* Assign a unique `id` to the listing. */
                  listing.id = 'listing-' + prop.id;
                  /* Assign the `item` class to each listing for styling. */
                  listing.className = 'item';
        
                  /* Add the link to the individual listing created above. */
                  var link = listing.appendChild(document.createElement('a'));
                  link.href = '#';
                  link.className = 'title';
                  link.id = 'link-' + prop.id;
                  link.innerHTML = prop.title;
        
                  /* Add details to the individual listing. */
                  var details = listing.appendChild(document.createElement('div'));
                  details.innerHTML = prop.date;
                  if (prop.phone) {
                    details.innerHTML += ' &middot; ' + prop.title;
                  }
        
                  /**
                   * Listen to the element and when it is clicked, do four things:
                   * 1. Update the `currentFeature` to the store associated with the clicked link
                   * 2. Fly to the point
                   * 3. Close all other popups and display popup for clicked store
                   * 4. Highlight listing in sidebar (and remove highlight for all other listings)
                   **/
                  link.addEventListener('click', function (e) {
                    for (var i = 0; i < data.length; i++) {
                      if (this.id === 'link-' + data[i].properties.id) {
                        var clickedListing = data[i];
                        flyToStore(clickedListing);
                        createPopUp(clickedListing);
                      }
                    }
                    var activeItem = document.getElementsByClassName('active');
                    if (activeItem[0]) {
                      activeItem[0].classList.remove('active');
                    }
                    this.parentNode.classList.add('active');
                  });
                });
              }
        
              /**
               * Use Mapbox GL JS's `flyTo` to move the camera smoothly
               * a given center point.
               **/
              function flyToStore(currentFeature) {
                map.flyTo({
                  center: currentFeature.geometry.coordinates,
                  zoom: 12
                });
              }
        
              /**
               * Create a Mapbox GL JS `Popup`.
               **/
              function createPopUp(currentFeature) {
                var popUps = document.getElementsByClassName('mapboxgl-popup');
                if (popUps[0]) popUps[0].remove();
                var popup = new mapboxgl.Popup({ closeOnClick: true, closeButton: true })
                  .setLngLat(currentFeature.geometry.coordinates)
                  .setHTML(
                    '<h3>' + currentFeature.properties.title + '</h3>' +
                      '<h4>' +
                      currentFeature.properties.title +
                      currentFeature.properties.id +
                      currentFeature.properties.date +
                      '<br />' +
                      '<br />' +
                      '<a href="https://apnehuskrs.pages.dev/blog/' + currentFeature.properties.slug +'">Les mer</a>' +
                      '</h4>'
                  )
                  .addTo(map);
              }
      
      /* https://krsapnehus.no/location/ */
      
      
      
          }
        }, [data]);
      
        
        
      
        return (
          <main className="App">
              <SEO title='Kart' 
              description='Kart over lokasjoner' />
            <div className="mapContainer">
              <div className="sidebar">
              <div className="sidebar-heading">
                <div className="heading">
                <Link id="404BackToHome" to="/">Gå til forsiden</Link>
                <a class="mobileOnly" href='#showListings'>Vis liste</a>
                
                  <h1 className="headingTitle">Steder</h1>
                </div>
                </div>

                <div id='showListings'>
                <div className="sidebar-content">
                <a class="mobileOnly" href='#'>Skjul liste</a>
                  <div id="listings" className="listings"></div>
                </div>
                </div>

              </div>
              {/* Mapbox Container */}
              <div className="map" ref={mapboxElRef} />
            </div>
          </main>
        );
      }
      
      export default MapPage;
